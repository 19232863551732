import React from 'react'

import { Container } from '@material-ui/core'

import Layout from '../components/Layout'

const MarkdownPage = ({ children, pageContext: { frontmatter } }) => {
	return (
		<Layout frontmatter={frontmatter}>
			<Container>{children}</Container>
		</Layout>
	)
}

export default MarkdownPage
